import { setCookie } from "@exp/scripts/helpers/cookies";
export { setAdsBlocked, forceFrequencyCookie };
function setAdsBlocked() {
  if (window.Exp.adsBlocked) return;
  window.Exp.adsBlocked = true;
  document.body.classList.add("ads-blocked");
  forceFrequencyCookie();
}
function forceFrequencyCookie() {
  const cookieOptions = window.Exp.bamData && window.Exp.bamData.frequencyCookie;
  if (!cookieOptions) return;
  setCookie(cookieOptions.name, true, {
    "max-age": cookieOptions.expire * 60 * 60
  });
}