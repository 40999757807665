import { getCookie } from "@exp/scripts/helpers/cookies";
import { setAdsBlocked, forceFrequencyCookie } from "@exp/scripts/helpers/ads";
import handleOutsiders from "./plugins/handleOutsiders";
function initRichMedia(bamData) {
  window.Bau = window.Bau || [];
  window.Exp = window.Exp || {};
  window.Exp.bamData = bamData;
  window.Exp.setAdsBlocked = setAdsBlocked;
  const targeting = window.Exp.bauTargeting || {};
  const extbltCookie = parseInt(getCookie("__extblt"));
  if (extbltCookie !== undefined && !isNaN(extbltCookie)) {
    targeting.experiment = extbltCookie;
  } else {
    targeting.experiment = 1;
  }
  let device;
  if (window.CHANNEL_DESKTOP) {
    device = "desktop";
  } else if (window.CHANNEL_TABLET) {
    device = "tablet";
  } else if (window.CHANNEL_MOBILE) {
    device = "mobile";
  }
  let richMediaRenderEvent;
  let fullPageWrapper = document.getElementsByClassName("fullpage-ad")[0];
  if (window.CHANNEL_DESKTOP) {
    const container = document.getElementsByTagName("article")[0];
    handleOutsiders.trim(container);
  }
  const user = window.Exp.user;
  if (user) {
    targeting.isLoggedIn = true;
    targeting.isPayingCustomer = user.entitlements && user.entitlements.length > 0;
  }
  if (window.expNative) {
    const trackingInfo = window.expNative.getTrackingInfo() || {};
    let fullAppName;
    try {
      fullAppName = trackingInfo.appName || JSON.parse(trackingInfo).appName;
    } catch (err) {
      // no-op
    }
    targeting.app = fullAppName?.includes("Android") ? "android" : "ios";
    targeting.isNativeApp = true;
  }
  let abortTimeout, consentNoticeVisible, fullPageAdLoaded;
  window.Bau.push(() => {
    const config = {
      pageType: window.Exp.genericPageType || "section",
      device,
      collapseBeforeAdFetch: false,
      targeting,
      scaleRichMediaAds: true
    };
    window.Bau.events.subscribe("slotRenderEnded", showAd);
    window.Bau.init(window.Exp.bamData, config);
    window.Bau.preloadAds(window.document.body);
    const richMediaAds = !consentNoticeVisible && document.getElementById("rich-media-ads");
    if (!richMediaAds) return;
    abortTimeout = window.setTimeout(abort, 3000);
    richMediaRenderEvent = window.Bau.events.subscribe("slotRenderEnded", setupRichMediaAds);
    window.Bau.displayAds(richMediaAds);
  });
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(Didomi => {
    consentNoticeVisible = Didomi.notice.isVisible();
    if (!consentNoticeVisible) return;
    window.clearTimeout(abortTimeout);
    Didomi.on("consent.changed", () => {
      if (fullPageAdLoaded) return;
      hideFullPageAd();
    });
  });
  function abort() {
    hideFullPageAd();
    forceFrequencyCookie();
  }
  function setupRichMediaAds(slotData) {
    if (!slotData) return;
    if (slotData.name === "rich_media_premium" || slotData.name === "mob_rich_media_premium") {
      handleFullPage(slotData);
    }
    if (window.CHANNEL_DESKTOP) {
      handleWallpaper(slotData);
    }
  }
  function handleFullPage(slotData) {
    if (!slotData.isEmpty) {
      initFullPageHeader();
    } else if (!consentNoticeVisible) {
      hideFullPageAd();
    }
    window.Bau.events.unsubscribe(richMediaRenderEvent);
  }
  let siteHeader, mainSectionLogo;
  function initFullPageHeader() {
    window.clearTimeout(abortTimeout);
    if (!fullPageWrapper) return;
    const fullPageHeader = fullPageWrapper.getElementsByClassName("fullpage-ad__header")[0];
    if (!fullPageHeader) return;
    fullPageAdLoaded = true;
    document.body.classList.add("fullpage-ad-visible");
    fullPageHeader.classList.remove("hidden");
    fullPageHeader.addEventListener("click", e => {
      e.preventDefault();
      hideFullPageAd();
    }, false);
  }
  function handleWallpaper(slotData) {
    if (slotData.name !== "rich_media_wallpaper" || slotData.isEmpty) return;
    const siteWrapper = document.getElementsByClassName("site-wrapper")[0];
    if (siteWrapper) {
      siteWrapper.classList.add("wallpaper");
      document.body.classList.add("wallpaper");
    }
    const wallpaperWrapper = document.getElementsByClassName("ad__rich_media_wallpaper")[0];
    if (wallpaperWrapper) {
      wallpaperWrapper.style.height = "auto";
      wallpaperWrapper.classList.add("wallpaper-spacing");
    }
    window.Bau.events.unsubscribe(richMediaRenderEvent);
  }
  function hideFullPageAd() {
    if (!fullPageWrapper) return;
    fullPageWrapper.classList.add("fullpage-ad--hidden");
    document.body.classList.remove("fullpage-ad-visible");
    window.setTimeout(removeWrapper, 300);
    function removeWrapper() {
      if (!fullPageWrapper) return;
      const parent = fullPageWrapper.parentElement;
      parent.removeChild(fullPageWrapper);
      fullPageWrapper = undefined;
      if (siteHeader) siteHeader.classList.remove("full-page-ad");
      if (mainSectionLogo) siteHeader.classList.remove("hidden");
    }
  }
  function showAd(_ref) {
    let {
      id,
      isEmpty
    } = _ref;
    if (isEmpty) return;
    const ad = document.getElementById(id);
    if (!ad) return;
    ad.classList.add("bam-ad-slot--visible");
  }
  window.Exp.initRichMedia = null;
}
window.Exp.initRichMedia = initRichMedia;